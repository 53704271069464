<template>
  <div class="form-group">
    <label v-if="label">{{ label }}</label>
    <div class="input-container  mr-2">
        <button   
            v-if="!tempImage"
            style="height: 40px"
            title="Upload"
            class="btn-upload mb-2"
            @click="selectFile"
        >
          <b-spinner v-if="loadingUpload" small></b-spinner>
          <i v-else class="uil uil-image-upload"></i>
        </button>
      </div>
      <div v-if="tempImage" class="row ml-2 mr-2">
      <img
          :src="tempImage"
          style="height: 40px"
          class="mb-2"
          :class="{ 'full-width': fullWidth }"
        /> 
      <button class="btn-upload btn-remove" 
            style="height: 40px"
            :disabled="loadingRemove"
            title="Clear" @click="deleteFile('login_page_logo')">
        <i class="uil uil-trash-alt"></i>
      </button>
      </div>
      <input
          ref="inputFile"
          :hide-input="iValue"
          type="file"
          class="form-control"
          style="display:none;"
          accept="image/*"
          @change="handleChanged"
        />
    </div>
</template>

<script>
export default {
  props: {
    resize: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },

    label: {
      type: String,
      default: null,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },

    businessId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      loadingRemove: false,
      loadingUpload: false,
      tempImage: null,
    }
  },

  computed: {
    iValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
  },

  mounted() {
    this.tempImage = this.value
  },

  methods: {

    selectFile() {
      this.$refs.inputFile.click()
    },

    choosedFile(file) {

      this.loadingUpload = true
      let uploadForm = new FormData()
      uploadForm.append('business_id', this.businessId)
      uploadForm.append('file', file)
      uploadForm.append('resize-image', this.resize)

      this.$store
        .dispatch('business/uploadImage', uploadForm)
        .then((res) => {
          this.iValue = res.path
          this.tempImage = res.path
          this.loadingUpload = false
        })
        .catch(() => {
          this.loadingUpload = false
        })
    },

    deleteFile() {
      this.loadingRemove = true
      const params = {
        path: this.iValue,
        business_id: this.businessId
      }
      this.$store
        .dispatch('business/deleteImage', params)
        .then((res) => {
          this.loadingRemove = null
          this.iValue = null
          this.tempImage = null
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },

    handleChanged() {
        this.choosedFile(this.$refs.inputFile.files[0])
    }
  },
}
</script>

<style lang="scss" scoped>
.input-container {
  display: flex;
  .form-control {
    flex: 1 1 auto;
  }
}
.btn-upload {
  background: transparent;
  border: 1px solid #e2e7f1;
  margin-left: 5px;
  border-radius: 5px;
  padding: 0px 11px;
  color: #414141;
  cursor: pointer;
  &.btn-remove {
    color: #ec0303;
    border-color: #ec0303;
  }
}

.splash-images {
  position: absolute;
  max-width: 100%;
  width: 370px;
  background: #fff;
  border: 1px solid #e2e7f1;
  border-radius: 3px;
  box-shadow: 0px 0px 4px #e2e7f1;
  z-index: 50;
  padding: 10px;
  right: 12px;
  top: 80px;
  img {
    cursor: pointer;
    width: 100px;
    margin: 5px;
    border-radius: 3px;
    &:hover {
      opacity: 0.5;
    }
  }
  &:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #e2e7f1;
    top: -10px;
    right: 58px;
  }
  &:after {
    position: absolute;
    content: ' ';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 9px solid #fff;
    top: -9px;
    right: 60px;
  }

  .list-images {
    max-height: 340px;
    overflow-y: scroll;
  }
}
</style>
